import request from '@/config/request.js'
const ApiOrder = request.Order
import tools from '@/util/tools'
const basic = tools.basic
export default {
  props: [],
  data() {
    const checkMoney = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入缴费金额'))
      } else {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的缴费金额'))
        } else {
          callback()
        }
      }
    }
    return {
      loading: false,
      visible: false,
      isDetail: false,
      dataForm: {
        accountId: '',
        recordType: 'SPENDING',
        tags: '',
        amount: '',
        recordDate: new Date(),
        remark: ''
      },
      rules: {
        amount: [
          { required: true, validator: checkMoney, trigger: 'blur' }
        ],
        recordDate: [
          {
            required: true,
            message: '请选择支出时间',
            trigger: 'change'
          }
        ],
        tags: [
          {
            required: true,
            message: '请选择支出类型',
            trigger: 'change'
          }
        ]
      },
      chooseTagOptions: [
        {enCode: 'MEALS', Text: '学生餐食'},
        {enCode: 'FRUIT_CUP', Text: '水果点心'},
        {enCode: 'STUDENT_GIFTS', Text: '学生礼品'},
        {enCode: 'TEACHIMG_AIDS', Text: '办公用品'},
        {enCode: 'CAMPAIGN', Text: '宣传活动'},
        {enCode: 'REPAIR', Text: '日常维修'},
        {enCode: 'WAGE', Text: '薪酬福利'},
        {enCode: 'COMMUNICATION', Text: '通讯交通'},
        {enCode: 'UTILITIES', Text: '水电煤气'},
        {enCode: 'RENT_PROPERTY', Text: '房租物业'},
        {enCode: 'PURCHASE_EQUIPMENT', Text: '固定资产'},
        {enCode: 'MISCELLANEOUS', Text: '低值易耗'},
        {enCode: 'INTRODUCING_COURSES', Text: '课程账号'},
        // {enCode: 'REFUND', Text: '学生退费'},
        {enCode: 'OTHER', Text: '其他'},
      ],
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    init(row) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['elForm'].resetFields()
        this.dataForm = basic.partData((row ? row: {}), [
          'accountId',
          {'recordType': {recordType: 'SPENDING'}},
          {tags: {tags: row.tags? ((row.tags.split(',')).filter(item => {return item !== ''})): []}},
          'amount',
          'recordDate',
          'remark'
        ])
      })

    },
    dataFormSubmit() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          let postUrl = ''
          let data = {}
          if (this.dataForm.accountId) {
            postUrl = 'onUpdateFinancialAccount'
            data = basic.partData(this.dataForm, [
              'accountId',
              {'recordType': {recordType: 'SPENDING'}},
              {tags: {tags: this.dataForm.tags.join()}},
              'amount',
              'recordDate',
              'remark'
            ])
          } else {
            postUrl = 'onAddFinancialAccount'
            data = basic.partData(this.dataForm, [
              {'recordType': {recordType: 'SPENDING'}},
              {tags: {tags: this.dataForm.tags.join()}},
              'amount',
              'recordDate',
              'remark'
            ])
          }
          ApiOrder[postUrl].call(this, data, (res) => {
            this.$message({
                message: '操作成功！',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refresh', true)
                }
              })
          })
        }
      })
    }
  }
}
