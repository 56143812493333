import request from '@/config/request.js'
const ApiOrder = request.Order
import common from '@/components/common/'
import ExpenseForm from './Form/index.vue'
import setting from "@/config/settings";
import moment from 'moment';
export default {
  name: 'expense-details',
  components: {
    ...common,
    ExpenseForm
  },
  data () {
    return {
      qiniu: setting.gbs.qiniu,
      loadtext: '正在加载中',
      listLoading: false,
      search_data: {
        filters: [{
          field: 'recordType',
          operator: 'eq',
          value: 'SPENDING'
        }, {
          field: 'tags',
          operator: 'like',
          value: ''
        }, {
          field: 'recordDate',
          operator: 'ge',
          value: ''
        }, {
          field: 'recordDate',
          operator: 'le',
          value: ''
        }],
        changeTime: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      },
      seachShow: false,
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10
      },
      tableList: [],
      formLabelWidth: '120px',
      chooseTagOptions: [
        {enCode: 'MEALS', Text: '学生餐食'},
        {enCode: 'FRUIT_CUP', Text: '水果点心'},
        {enCode: 'STUDENT_GIFTS', Text: '学生礼品'},
        {enCode: 'TEACHIMG_AIDS', Text: '办公用品'},
        {enCode: 'CAMPAIGN', Text: '宣传活动'},
        {enCode: 'REPAIR', Text: '日常维修'},
        {enCode: 'WAGE', Text: '薪酬福利'},
        {enCode: 'COMMUNICATION', Text: '通讯交通'},
        {enCode: 'UTILITIES', Text: '水电煤气'},
        {enCode: 'RENT_PROPERTY', Text: '房租物业'},
        {enCode: 'PURCHASE_EQUIPMENT', Text: '固定资产'},
        {enCode: 'MISCELLANEOUS', Text: '低值易耗'},
        {enCode: 'INTRODUCING_COURSES', Text: '课程账号'},
        // {enCode: 'REFUND', Text: '学生退费'},
        {enCode: 'OTHER', Text: '其他'},
      ],
      formVisible: false,
      expenditure: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  created () {
    this.initData();
  },
  methods: {
    onDelete (row) {
      this.$confirm('确定删除该记录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiOrder.removeFinancialAccountById.call(this, {accountId: row.accountId}, (res => {
          this.initData();
        }))
      })
    },
    getPayTime (val) {
      if (val && val.length > 0) {
        this.search_data.filters[2].value = val[0]
        this.search_data.filters[3].value = val[1]
      }
    },
    getRowDetails (row = {}) {
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.ExpenseForm.init(row)
      })
    },
    onResetSearch () {
      this.search_data.filters[2].value = ''
      this.search_data.filters[3].value = ''
      this.$refs.search_data.resetFields()
        // 需要给分页组件传的信息
       this.paginations.current_page = 1
      this.search_data.changeTime = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      this.initData()
      this.initExpenditureStatistics()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.initData()
      this.initExpenditureStatistics()
    },
    initData () {
      this.listLoading = true;
      if (this.search_data.changeTime && this.search_data.changeTime.length > 0) {
        this.search_data.filters[2].value = this.search_data.changeTime[0]
        this.search_data.filters[3].value = this.search_data.changeTime[1]
      } else {
        this.search_data.filters[2].value = ''
        this.search_data.filters[3].value = ''
      }
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'recordDate'
      }
      let search = {
        filters: []
      }
      this.search_data.filters.map(item => {
        if (item.value) {
          search.filters.push(item)
        }
      })
      let _query = {
        ...data,
        ...search,
      };

      ApiOrder.getFinancialAccountPage.call(this,_query, (res => {
        this.tableList = res.content.map(item => {
          if (item.picturePaths && item.picturePaths.length > 0){
            item.picturePaths = item.picturePaths.map(img => {
              return this.qiniu + img
            })
          }
          return item
        })
        // this.tableList = res.content;
        this.paginations.total = parseInt(res.totalElements)
        this.listLoading = false;
      }))
    },
    initExpenditureStatistics () {
      if (this.search_data.changeTime && this.search_data.changeTime.length > 0) {
        this.search_data.filters[2].value = this.search_data.changeTime[0]
        this.search_data.filters[3].value = this.search_data.changeTime[1]
      } else {
        this.search_data.filters[2].value = ''
        this.search_data.filters[3].value = ''
      }
      var data = {
        offset: 20000,
        limit: 0,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'recordDate'
      }
      let search = {
        filters: []
      }
      this.search_data.filters.map(item => {
        if (item.value) {
          search.filters.push(item)
        }
      })
      let _query = {
        ...data,
        ...search,
      };

      ApiOrder.postExpenditureStatistics.call(this,_query, (listData => {
        let expenditure = (listData.expenditure+'').split('.')
        this.expenditure = expenditure.length > 1 ? expenditure : [...expenditure, "00"]
      }))
    },
  },
  mounted () {
    this.initExpenditureStatistics()
  }
}
