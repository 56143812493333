import request from '@/config/request.js'
import common from '@/components/common/'
const ApiTeacherAttendance = request.TeacherAttendance
export default {
  name: 'teacher-attendance',
  components: {
    ...common,
  },
  data () {
    return {
      loadtext: '正在加载中',
      loadtextNum: '',
      search_data: {
        filters: [{
          field: 'teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'teacherSignState',
          operator: 'like',
          value: ''
        }, {
          field: 'signOnType',
          operator: 'like',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      teacherSignState: [
        {
          label: '正常',
          value: 'NORMAL'
        }, {
          label: '异常',
          value: 'ABNORMAL'
        }
      ],
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: ''
      },
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiTeacherAttendance.exportTeacherAttendance.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    getDetail (row) {
      this.DetailFormVisible = true
      this.dateDetail = row
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.initData()
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onLoading () {
      switch (this.loadtextNum.length) {
        case 0:
          this.loadtextNum = '.'
          break
        case 1:
          this.loadtextNum = '..'
          break
        case 2:
          this.loadtextNum = '...'
          break
        default:
          this.loadtextNum = ''
          break
      }
    },
    initData () {
      this.listLoading = true;
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'attendDate'
      }
      let search = {
        filters: []
      }
      this.search_data.filters.map(item => {
        if (item.value !== '') {
          search.filters.push(item)
        }
      })
      let _query = {
        ...data,
        ...search,
      };
      ApiTeacherAttendance.getTeacherAttendanceList.call(this, _query, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
      })
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'teacherSignState',
          operator: 'like',
          value: ''
        }, {
          field: 'signOnType',
          operator: 'like',
          value: ''
        }]
      }
      this.onSearch()
    },
  },
  mounted () {
    this.initData()
  }
}
